import { SimpleExpansionPanel } from '../../../components/SimpleExpansionPanel';
import { EditPracticeContractSpendTermsAction } from './EditPracticeContractSpendTermsAction.graphql';
import type { PracticeContractSpendTermInfo, RefetchContracts } from './PracticeContracts.types';
import {
    CalculatePracticeAggregateSpendOverPeriod_Query,
    ListPracticeContractsV2ByOrganizationId_Query,
} from './PracticeContractsTable.graphql';
import { useQuery } from '@apollo/client';
import { MUITable } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { ChevronLeft, Collapse, FlossPalette, IconButton, Text, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

interface PracticeContract {
    id: string;
    created_at: string;
    effective_start_date?: string | null;
    effective_end_date?: string | null;
    spend_terms: PracticeContractSpendTermInfo[];
}

interface PartnerAggregateSpendProps {
    partnerId: string;
    termInfo: PracticeContractSpendTermInfo;
}

const Stacked = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
});

const PartnerAggregateSpend: React.FC<PartnerAggregateSpendProps> = ({ partnerId, termInfo }) => {
    const { effective_start_date, effective_end_date, spend_cycle_length } = termInfo;
    const { data } = useQuery(CalculatePracticeAggregateSpendOverPeriod_Query, {
        variables: {
            partnerId: partnerId,
            periodStart: effective_start_date ?? '',
            periodEnd: effective_end_date ?? '',
        },
        skip: spend_cycle_length === 1 || !effective_start_date || !effective_end_date,
    });
    const aggregateSpend = data?.calculatePracticeAggregateSpendOverPeriod ?? 0;

    return termInfo.spend_cycle_length === 1 ? (
        <Text variant={'body2'}>n/a</Text>
    ) : (
        <Stacked>
            <Text variant={'body2'}>{Format.currency(termInfo.spend_minimum_cents - aggregateSpend)}</Text>
            <Text color={'GRAY'} variant={'body2'}>
                ({Format.currency(aggregateSpend)} spent)
            </Text>
        </Stacked>
    );
};

const ContractSpecificationsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const EditContainer = styled('div')({
    marginLeft: 'auto',
});

const CollapsibleContractContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '64px',
    '& + &': {
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    },
});

const StyledTitle = styled(Text)({
    margin: '0px 4px 0px 8px',
});

const ContractTable: React.FC<{ partnerId: string; contract: PracticeContract }> = ({ partnerId, contract }) => (
    <MUITable<PracticeContractSpendTermInfo>
        title={''}
        data={contract.spend_terms ?? []}
        displayOptions={{ elevation: 0 }}
        paginationOptions={{ disable: true }}
        toolbarOptions={{ hidden: true }}
        columns={[
            {
                name: 'Term type',
                render: r => (r.spend_cycle_length === 1 ? 'Monthly' : 'Multi-month'),
            },
            {
                name: 'Start month',
                type: 'date',
                defaultSort: 'desc',
                render: r =>
                    r.effective_start_date ? moment.utc(r.effective_start_date).format('MMM YYYY') : 'Not set',
            },
            {
                name: 'End month',
                type: 'date',
                render: r => (r.effective_end_date ? moment.utc(r.effective_end_date).format('MMM YYYY') : 'Not set'),
            },
            {
                name: 'Minimum spend',
                type: 'currency',
                field: 'spend_minimum_cents',
                render: r => (
                    <Stacked>
                        <Text color={'GRAY'} variant={'body2'}>
                            {r.spend_cycle_length === 1 ? 'Monthly min' : 'Aggregate min'}:
                        </Text>
                        <Text variant={'body2'}>{Format.currency(r.spend_minimum_cents)}</Text>
                    </Stacked>
                ),
            },
            {
                name: 'Remaining spend',
                type: 'currency',
                render: r => <PartnerAggregateSpend partnerId={partnerId} termInfo={r} />,
            },
        ]}
    />
);

const CollapsibleRow: React.FC<{ partnerId: string; contract: PracticeContract; refetch: RefetchContracts }> = ({
    partnerId,
    contract,
    refetch,
}) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const [isOpen, setIsOpen] = React.useState(false);
    const title = contract.effective_start_date
        ? `${moment.utc(contract.effective_start_date).format('MMM YYYY')} Contract`
        : 'Contract Pending Practice Activation';
    const isContractNoLongerInEffect =
        contract.effective_end_date && moment.utc(contract.effective_end_date) < moment.utc();
    const isEditable =
        isOpen && contract.spend_terms.length && !isContractNoLongerInEffect && contract.effective_start_date !== null;
    return (
        <>
            <CollapsibleContractContainer>
                <IconButton size={'small'} onClick={() => setIsOpen(!isOpen)}>
                    <ChevronLeft
                        style={{
                            transition: 'all 0.2s linear',
                            transform: isOpen ? 'rotate(-90deg)' : `rotate(0)`,
                        }}
                    />
                </IconButton>
                <StyledTitle>{title}</StyledTitle>
                {!disableContractEdits && isEditable && (
                    <EditContainer>
                        <EditPracticeContractSpendTermsAction
                            contractId={contract.id}
                            spendTerms={contract.spend_terms}
                            refetch={refetch}
                        />
                    </EditContainer>
                )}
            </CollapsibleContractContainer>
            <Collapse in={isOpen} unmountOnExit style={{ width: '100%' }}>
                <ContractTable partnerId={partnerId} contract={contract} />
            </Collapse>
        </>
    );
};

export const LegacyPracticeContractsTable: React.FC<{ practiceId: string }> = ({ practiceId }) => {
    const { data: { listPracticeContractsV2ByOrganizationId: contracts = [] } = {}, refetch: refetchContracts } =
        useQuery<{ listPracticeContractsV2ByOrganizationId: PracticeContract[] }>(
            ListPracticeContractsV2ByOrganizationId_Query,
            {
                variables: { organizationId: practiceId },
            },
        );

    const sortedContracts = [...contracts].sort((a, b) => {
        const aDate = a.effective_start_date ?? a.created_at;
        const bDate = b.effective_start_date ?? b.created_at;
        return aDate > bDate ? -1 : 1;
    });

    return (
        <SimpleExpansionPanel
            ExpansionPanelProps={{
                variant: 'outlined',
                style: {
                    margin: '10px 24px 10px 0px !important',
                    width: 'inherit',
                },
            }}
            ExpansionPanelSummaryProps={{
                style: {
                    height: '56px',
                },
            }}
            title={<Text variant={'h5'}>Contract specifications</Text>}
        >
            <ContractSpecificationsContainer>
                {sortedContracts.length ? (
                    sortedContracts.map(contract => (
                        <CollapsibleRow
                            key={contract.id}
                            partnerId={practiceId}
                            contract={contract}
                            refetch={refetchContracts}
                        />
                    ))
                ) : (
                    <Text variant={'body1'}>No contracts found</Text>
                )}
            </ContractSpecificationsContainer>
        </SimpleExpansionPanel>
    );
};
